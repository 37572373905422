export const powDocs = {
  name: 'pow',
  category: 'Operators',
  syntax: [
    'x ^ y',
    'pow(x, y)'
  ],
  description:
      'Calculates the power of x to y, x^y.',
  examples: [
    '2^3',
    '2*2*2',
    '1 + e ^ (pi * i)'
  ],
  seealso: [
    'multiply',
    'nthRoot',
    'nthRoots',
    'sqrt'
  ]
}
